@import '../../../node_modules/foundation-sites/scss/util/unit';
@import '../../../node_modules/foundation-sites/scss/util/breakpoint';
@import '../../../node_modules/foundation-sites/scss/util/mixins';
@import "../setup/colors";
$global-flexbox: true;

.products-carousel-wrapper {
  &.full-width{
    padding: 0 10px;
    .products-carousel{
      padding: 0;
    }
  }
  .products-carousel{
    padding: 0 45px;

    @include breakpoint (large){
      padding: 0;
    }
    @include breakpoint (small only){
      padding: 0;
    }

    .slides{
      position: relative;
      padding: 0 5px;

      .owl-stage-outer{
        overflow: hidden;

        .owl-stage {
          @include clearfix;

          .owl-item {
            float: left;
          }
        }
      }

      .owl-nav{
        .owl-prev,
        .owl-next {
          position: absolute;
          width: 15px;
          height: 15px;
          top: 40%;
          font-size: rem-calc(28);
          color: $black;
          margin-top: -19px;
          transition: opacity 0.3s ease;

          &::after{
            content: '';
            border-style: solid;
            display: block;
            height: 9px;
            width: 9px;
            transform: rotate(45deg);
          }
        }
        .owl-prev {
          left: -6px;
          &::after{
            border-width: 0 0 2px 2px;
          }
        }
        .owl-next {
          right: -12px;
          &::after{
            border-width: 2px 2px 0 0;
          }
        }
        @include breakpoint (small only){
          display: none !important;
        }
      }

      .owl-dots {
        position: relative;
        display: none;

        @include breakpoint (small only){
          display: flex;
        }
      }

      &[data-scrollable='false']{
        .owl-dots, .owl-nav{
          display: none;
        }
      }

    }
  }

  @include breakpoint (medium) {
    .margin-top-mobile, .margin-bottom-mobile{
      display: none !important;
    }
  }
  @include breakpoint (small only){
    .margin-top-desktop, .margin-bottom-desktop{
      display: none !important;
    }
  }
}
